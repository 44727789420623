exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ahamtoshivam-js": () => import("./../../../src/pages/ahamtoshivam.js" /* webpackChunkName: "component---src-pages-ahamtoshivam-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dakshinakali-js": () => import("./../../../src/pages/dakshinakali.js" /* webpackChunkName: "component---src-pages-dakshinakali-js" */),
  "component---src-pages-devichandi-js": () => import("./../../../src/pages/devichandi.js" /* webpackChunkName: "component---src-pages-devichandi-js" */),
  "component---src-pages-devimathangi-js": () => import("./../../../src/pages/devimathangi.js" /* webpackChunkName: "component---src-pages-devimathangi-js" */),
  "component---src-pages-devivarahi-js": () => import("./../../../src/pages/devivarahi.js" /* webpackChunkName: "component---src-pages-devivarahi-js" */),
  "component---src-pages-eshwaridhyana-js": () => import("./../../../src/pages/eshwaridhyana.js" /* webpackChunkName: "component---src-pages-eshwaridhyana-js" */),
  "component---src-pages-eshwarikalavahana-js": () => import("./../../../src/pages/eshwarikalavahana.js" /* webpackChunkName: "component---src-pages-eshwarikalavahana-js" */),
  "component---src-pages-eshwarikriya-js": () => import("./../../../src/pages/eshwarikriya.js" /* webpackChunkName: "component---src-pages-eshwarikriya-js" */),
  "component---src-pages-immortalprocess-js": () => import("./../../../src/pages/immortalprocess.js" /* webpackChunkName: "component---src-pages-immortalprocess-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-navarathri-js": () => import("./../../../src/pages/navarathri.js" /* webpackChunkName: "component---src-pages-navarathri-js" */),
  "component---src-pages-punarjeevana-js": () => import("./../../../src/pages/punarjeevana.js" /* webpackChunkName: "component---src-pages-punarjeevana-js" */),
  "component---src-pages-sanskar-js": () => import("./../../../src/pages/sanskar.js" /* webpackChunkName: "component---src-pages-sanskar-js" */),
  "component---src-pages-sri-js": () => import("./../../../src/pages/sri.js" /* webpackChunkName: "component---src-pages-sri-js" */),
  "component---src-pages-srikari-js": () => import("./../../../src/pages/srikari.js" /* webpackChunkName: "component---src-pages-srikari-js" */),
  "component---src-pages-srinaada-js": () => import("./../../../src/pages/srinaada.js" /* webpackChunkName: "component---src-pages-srinaada-js" */),
  "component---src-pages-srivasukinagaeswaritemple-js": () => import("./../../../src/pages/srivasukinagaeswaritemple.js" /* webpackChunkName: "component---src-pages-srivasukinagaeswaritemple-js" */),
  "component---src-pages-srividhyaeshwarisadhana-js": () => import("./../../../src/pages/srividhyaeshwarisadhana.js" /* webpackChunkName: "component---src-pages-srividhyaeshwarisadhana-js" */)
}

